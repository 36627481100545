.hero {
  padding-top: 15vw;
  background: url('/rainbow-header.svg') no-repeat center -20vw;
  background-size: 110% auto;
  min-height: 30vw;
  border-bottom: 40px solid #ffa2ce;
}

.rainbow {
  padding-top: 25vw;
  background: url('/rainbow.svg') no-repeat center top;
  background-size: 110% auto;
  min-height: 30vw;

  border-bottom: 40px solid #ffe771;
}

.social {
  padding-bottom: 25vw;
  background: url('/rainbow.svg') no-repeat center bottom;
  background-size: 110% auto;
  min-height: 30vw;
}
